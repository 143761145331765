@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

// Light mode text styles
:root {
  --background-color: #F3F3F3;
  --text-color: rgba(32, 32, 32, 0.70);
  --text-variant-color: rgba(32, 32, 32, 0.40);
  --h1-color: #202020;
  --h2-color: rgba(32, 32, 32, 0.90);
  --h3-color: #202020;
  --caption-color: rgba(32, 32, 32, 0.50);
  --p-color: rgba(32, 32, 32, 0.80);
  --primary-button-color: #91D2DB;
  --item-background: #EBEBEB;


  --menu-bar-color: rgba(255, 255, 255, 0.75);
  --menu-bar-border-color: rgb(205, 206, 206);
  --menu-item-selected-bg: #202020;
  --menu-item-selected-color: #FFFFFF;

  --grey1: rgba(32, 32, 32, 0.60);
  --grey2: rgba(32, 32, 32, 0.50);
  --primary: #202020;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

// UI Elements
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Pretendard-Regular', sans-serif;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

a {
  text-decoration: underline;
}

a.smooth-scroll {
  text-decoration: none;
}

h3 {
  color: var(--h3-color);
  font-family: "Pretendard-Regular";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.249px;
}

.caption,
li {
  color: var(--caption-color);
  font-family: "Pretendard-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.249px;
}

.caption {
  padding-bottom: 24px;
}

.icon {
  height: 20px;
  margin-top: 20px;

  span {
    height: 20px;
    width: 20px;
    display: inline-block;
    fill: #202020;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    filter: invert(0.5);

  }

  #flutter {
    background-image: url(../assets/icons/tech/flutter.svg);
  }

  #rive {
    background-image: url(../assets/icons/tech/rive.svg);
  }

  #angular {
    background-image: url(../assets/icons/tech/angular.svg);
  }

  #elasticsearch {
    background-image: url(../assets/icons/tech/elasticsearch.svg);
  }

  #firebase {
    background-image: url(../assets/icons/tech/firebase.svg);
  }

  #pocketbase {
    background-image: url(../assets/icons/tech/pocketbase.svg);
  }
}

.main_container {
  max-width: 1200px;
}

#main-content {
  overflow-y: scroll;
  position: relative;
  width: 100%;
  height: 100%;
}


h1 {
  font-family: "paralucent", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 72px;
  color: var(--h1-color);

  span {
    background: var(--async2, linear-gradient(96deg, #2F9FDE 15.26%, #84D3C0 80.12%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

h2 {
  font-family: "paralucent", sans-serif;
  font-size: 52px;
  color: var(--h2-color);
}

p {
  padding-bottom: 32px;
  color: var(--p-color);
  font-family: "Pretendard-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.249px;
}


.grey1 {
  color: var(--grey1);
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.grey2 {
  color: var(--grey2);
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.41px;
}

.primary {
  color: var(--primary);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contact_button {
  color: var(--primary);
  font-family: Paralucent;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#logo {
  position: absolute;
  top: 43px;
  left: 24px;
  z-index: 2
}

#menu_bar {
  z-index: 1;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%);

  #menu-container {
    background-color: var(--menu-bar-color);
    border-color: var(--menu-bar-border-color) !important;
    border: 1px solid;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 200;

    #right_menu {
      #background-highlight {
        background-color: var(--menu-item-selected-bg);
        border-radius: 100px;
        z-index: -1;

      }

      span {
        border-radius: 100px;
        transition: color 0.5s ease;
        /* Animate text color over 0.5 seconds */

        &.selected {

          color: var(--menu-item-selected-color);

        }
      }
    }
  }
}

#hero {
  height: 860px;
  width: 100%;
  max-width: 1200px;
  padding-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: auto;

  #hero_bg {
    background-image: url(../assets/images/herobackground.webp);
    background-size: cover;
    height: 1000px;
  }

  #slogan {
    font-size: 72px;
    line-height: 75px;
    margin-bottom: 24px;
  }

  .card_customizer {
    margin: auto;
    height: 580px;
    background-image: url(../assets/images/cardcustomizer.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

#clients {
  .logo {
    filter: invert(0.6);

    &#gradient img {
      max-height: 40px;
    }

    &#haumea img {
      max-height: 30px
    }

    &#proxima img {
      max-height: 30px
    }

    &#crowdpoint img {
      max-height: 20px;
    }

    &#bloxley img {
      max-height: 40px;
    }

    &#odubu img {
      max-height: 40px;
    }

  }

}

#about {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  text-align: left;
  padding-top: 40px;
  padding-bottom: 48px;
}

#projects {

  div.tile {
    flex-shrink: 0;

    .flip-card-front {
      background: #e5e5e5;
      border-radius: 8px;
      background-size: cover;
    }

    .flip-card-back {
      background: #e5e5e5;
      border-radius: 8px;
    }
  }

  #my-tiny-room {
    background-image: url(../assets/images/tinyroom.webp);
  }

  #proximacentauri {
    background-image: url(../assets/images/proximacentauri.webp);
  }

  #haumea {
    background-image: url(../assets/images/haumea_light.webp);
  }

  #minimemo {
    background-image: url(../assets/images/minimemo.webp);
  }

  #gradient {
    background-image: url(../assets/images/gradient.webp);
  }

  #bloxley {
    background-image: url(../assets/images/bloxley_light.webp);
  }

}

#back_to_home {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: 1.5px solid #AADAFA;
  transition: all 1s;
  opacity: 0;
  z-index: -1;
  background-color: var(--ba);


  &.visible {
    opacity: 1;
    z-index: 1;
  }

  img {
    width: 24px;
    filter: invert(1);
    transform: rotate(-90deg) translate(-18px, 11px);

  }
}

#footer {
  #back_to_top {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 50px;
    border: 1.5px solid #AADAFA;
    color: var(--primary);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    img {
      filter: invert(1);
    }
  }

}

#footer_logo {
  margin-top: 42px;
  height: 100px;
  background-image: url(../assets/xilinus/xilinus_footer.svg);
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.m-auto {
  margin: auto;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.min-h-full {
  min-height: 100%;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.flex-initial {
  flex: 0 auto;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.text-left {
  text-align: left;
}

.font-bold {
  font-weight: 700;
}

.underline {
  text-decoration-line: underline;
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Pretendard-Regular;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

:root {
  --background-color: #f3f3f3;
  --text-color: #202020b3;
  --text-variant-color: #20202066;
  --h1-color: #202020;
  --h2-color: #202020e6;
  --h3-color: #202020;
  --caption-color: #20202080;
  --p-color: #202020cc;
  --primary-button-color: #91d2db;
  --item-background: #ebebeb;
  --menu-bar-color: #ffffffbf;
  --menu-bar-border-color: #cdcece;
  --menu-item-selected-bg: #202020;
  --menu-item-selected-color: #fff;
  --grey1: #20202099;
  --grey2: #20202080;
  --primary: #202020;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  max-width: 100vw;
  margin: 0;
  padding: 0;
  font-family: Pretendard-Regular, sans-serif;
}

a {
  text-decoration: underline;
}

a.smooth-scroll {
  text-decoration: none;
}

h3 {
  color: var(--h3-color);
  letter-spacing: .249px;
  font-family: Pretendard-Regular;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.caption, li {
  color: var(--caption-color);
  letter-spacing: .249px;
  font-family: Pretendard-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.caption {
  padding-bottom: 24px;
}

.icon {
  height: 20px;
  margin-top: 20px;
}

.icon span {
  fill: #202020;
  filter: invert(.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.icon #flutter {
  background-image: url("flutter.e21a3774.svg");
}

.icon #rive {
  background-image: url("rive.3b2feff5.svg");
}

.icon #angular {
  background-image: url("angular.053997ea.svg");
}

.icon #elasticsearch {
  background-image: url("elasticsearch.dc1f5685.svg");
}

.icon #firebase {
  background-image: url("firebase.7fd1a4cf.svg");
}

.icon #pocketbase {
  background-image: url("pocketbase.8a487d70.svg");
}

.main_container {
  max-width: 1200px;
}

#main-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}

h1 {
  color: var(--h1-color);
  font-family: paralucent, sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}

h1 span {
  background: var(--async2, linear-gradient(96deg, #2f9fde 15.26%, #84d3c0 80.12%));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

h2 {
  color: var(--h2-color);
  font-family: paralucent, sans-serif;
  font-size: 52px;
}

p {
  color: var(--p-color);
  letter-spacing: .249px;
  padding-bottom: 32px;
  font-family: Pretendard-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.grey1 {
  color: var(--grey1);
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.grey2 {
  color: var(--grey2);
  letter-spacing: .41px;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.primary {
  color: var(--primary);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contact_button {
  color: var(--primary);
  font-family: Paralucent;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#logo {
  z-index: 2;
  position: absolute;
  top: 43px;
  left: 24px;
}

#menu_bar {
  z-index: 1;
  -webkit-mask-image: linear-gradient(to top, #0000 0%, #000 10%);
  mask-image: linear-gradient(to top, #0000 0%, #000 10%);
}

#menu_bar #menu-container {
  background-color: var(--menu-bar-color);
  -webkit-backdrop-filter: blur(10px);
  z-index: 200;
  border: 1px solid;
  border-color: var(--menu-bar-border-color) !important;
}

#menu_bar #menu-container #right_menu #background-highlight {
  background-color: var(--menu-item-selected-bg);
  z-index: -1;
  border-radius: 100px;
}

#menu_bar #menu-container #right_menu span {
  border-radius: 100px;
  transition: color .5s;
}

#menu_bar #menu-container #right_menu span.selected {
  color: var(--menu-item-selected-color);
}

#hero {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 860px;
  margin: auto;
  padding-top: 60px;
  display: flex;
}

#hero #hero_bg {
  background-image: url("herobackground.f902c2e2.webp");
  background-size: cover;
  height: 1000px;
}

#hero #slogan {
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 75px;
}

#hero .card_customizer {
  background-image: url("cardcustomizer.0b87b814.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 580px;
  margin: auto;
}

#clients .logo {
  filter: invert(.6);
}

#clients .logo#gradient img {
  max-height: 40px;
}

#clients .logo#haumea img, #clients .logo#proxima img {
  max-height: 30px;
}

#clients .logo#crowdpoint img {
  max-height: 20px;
}

#clients .logo#bloxley img, #clients .logo#odubu img {
  max-height: 40px;
}

#about {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 48px;
  display: flex;
}

#projects div.tile {
  flex-shrink: 0;
}

#projects div.tile .flip-card-front {
  background: #e5e5e5 0 0 / cover;
  border-radius: 8px;
}

#projects div.tile .flip-card-back {
  background: #e5e5e5;
  border-radius: 8px;
}

#projects #my-tiny-room {
  background-image: url("tinyroom.e101cbde.webp");
}

#projects #proximacentauri {
  background-image: url("proximacentauri.9afa7798.webp");
}

#projects #haumea {
  background-image: url("haumea_light.5be3b290.webp");
}

#projects #minimemo {
  background-image: url("minimemo.98780bc5.webp");
}

#projects #gradient {
  background-image: url("gradient.713a171a.webp");
}

#projects #bloxley {
  background-image: url("bloxley_light.d26592e3.webp");
}

#back_to_home {
  opacity: 0;
  z-index: -1;
  background-color: var(--ba);
  border: 1.5px solid #aadafa;
  border-radius: 50px;
  width: 48px;
  height: 48px;
  transition: all 1s;
  position: fixed;
  bottom: 24px;
  right: 24px;
}

#back_to_home.visible {
  opacity: 1;
  z-index: 1;
}

#back_to_home img {
  filter: invert();
  width: 24px;
  transform: rotate(-90deg)translate(-18px, 11px);
}

#footer #back_to_top {
  color: var(--primary);
  border: 1.5px solid #aadafa;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 16px 24px;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

#footer #back_to_top img {
  filter: invert();
}

#footer_logo {
  background-image: url("xilinus_footer.315d56ef.svg");
  height: 100px;
  margin-top: 42px;
}

@media (width >= 640px) {
  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
}

/*# sourceMappingURL=index.a51a7b20.css.map */
